import classnames from 'classnames';
import Image from 'next/image';
import React, { useEffect, useState } from 'react';

import LinkWithStyle from '@cineamo/legacy-frontend-lib/src/components/ui/link/LinkWithStyle';

import { CinemaDto } from '@cineamo/legacy-frontend-lib/src/models/cinema/CinemaDto.types';

import { getCinemaInitials, getCinemaLogo, getDeterministicColorFromString } from '@/src/helper/cinema-helper';
import { loadAsset } from '@cineamo/legacy-frontend-lib/src/helper/asset-helper';

import { IconLocation24 } from '@cineamo/legacy-frontend-lib/src/icons/menu/ic-location';

const defaultCinemaImage = loadAsset('/images/placeholders/im-cinemaDummy.png');

export type CinemaProfileImageProps = {
    className?: string;
    classNameInitials?: string;
    size?: 'custom' | '24' | '32' | '36' | '40' | '52' | '56' | '72' | '96';
    cinema: CinemaDto;
    href?: string;
};

export default function CinemaProfileImageRound(props: CinemaProfileImageProps) {
    const { className, cinema } = props;

    const size = props.size || '24';

    const [error, setError] = useState(false);

    useEffect(() => {
        setError(false);
    }, [cinema]);

    if (!cinema) {
        return <IconLocation24 className="flex-none" />;
    }

    let classNameSize: string;
    let textSize: string;
    switch (size) {
        case 'custom':
            break;
        case '32':
            classNameSize = 'w-32 h-32';
            textSize = 'text-body-bold-12';
            break;
        case '36':
            classNameSize = 'w-36 h-36';
            textSize = 'text-body-bold-12';
            break;
        case '40':
            classNameSize = 'w-40 h-40';
            textSize = 'text-body-bold-12';
            break;
        case '52':
            classNameSize = 'w-52 h-52';
            textSize = 'text-body-bold-14';
            break;
        case '56':
            classNameSize = 'w-56 h-56';
            textSize = 'text-body-bold-14';
            break;
        case '72':
            classNameSize = 'w-72 h-72';
            textSize = 'text-body-bold-16';
            break;
        case '96':
            classNameSize = 'w-96 h-96';
            textSize = 'text-body-bold-16';
            break;
        default:
            classNameSize = 'w-24 h-24';
            textSize = 'text-body-bold-10';
            break;
    }

    const imageUrl = getCinemaLogo(cinema, true);

    return (
        <LinkWithStyle href={props.href}>
            <div
                className={classnames(
                    className,
                    classNameSize,
                    'flex-none flex flex-col justify-center relative overflow-hidden rounded-full'
                )}
                title={cinema.name}
                style={{ backgroundColor: getDeterministicColorFromString(cinema.name) }}>
                {!imageUrl ? (
                    <div className="w-full h-full flex justify-center items-center transition ease-in duration-300">
                        <span className={classnames('text-white', props.classNameInitials || textSize)}>
                            {getCinemaInitials(cinema)}
                        </span>
                    </div>
                ) : (
                    <Image
                        className="w-full h-full object-cover"
                        src={!error && imageUrl ? imageUrl : defaultCinemaImage}
                        width={200}
                        height={200}
                        onError={() => setError(true)}
                        onErrorCapture={() => setError(true)}
                        alt={`${cinema.name} cinema image`}
                    />
                )}
            </div>
        </LinkWithStyle>
    );
}
