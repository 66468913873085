import {CustomSVGProps} from '../types/CustomSVGProps.types';

export function IconLibrary48(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="48"
             height="48" 
             viewBox="0 0 48 48"
             fill="none" 
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" 
                  clipRule="evenodd"
                  d="M31.2589 6.3723L39.181 5.25892L44.1913 40.9086L36.2691 42.022L31.2589 6.3723ZM29.2783 6.65065C29.1246 5.55683 29.8867 4.54549 30.9805 4.39176L38.9027 3.27838C39.9965 3.12465 41.0078 3.88675 41.1616 4.98057L46.1718 40.6302C46.3255 41.724 45.5634 42.7354 44.4696 42.8891L36.5475 44.0025C35.4536 44.1562 34.4423 43.3941 34.2886 42.3003L29.2783 6.65065ZM25 5.93769H17V41.9377H25V5.93769ZM17 3.93769C15.8954 3.93769 15 4.83312 15 5.93769V41.9377C15 43.0423 15.8954 43.9377 17 43.9377H25C26.1046 43.9377 27 43.0423 27 41.9377V5.93769C27 4.83312 26.1046 3.93769 25 3.93769H17ZM11 5.93769H3L3 41.9377H11V5.93769ZM3 3.93769C1.89543 3.93769 1 4.83313 1 5.9377V41.9377C1 43.0423 1.89543 43.9377 3 43.9377H11C12.1046 43.9377 13 43.0423 13 41.9377V5.93769C13 4.83313 12.1046 3.93769 11 3.93769H3Z"
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}

export function IconLibrary24(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="24" 
             height="24" 
             viewBox="0 0 24 24" 
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" 
                  clipRule="evenodd"
                  d="M17.4857 20.5206L15.2589 4.67636L18.2297 4.25884L20.4565 20.1031L17.4857 20.5206ZM13.1392 3.96444C13.0623 3.41753 13.4434 2.91186 13.9903 2.83499L18.9416 2.13913C19.4885 2.06227 19.9942 2.44331 20.071 2.99022L22.5762 20.815C22.653 21.362 22.272 21.8676 21.7251 21.9445L16.7737 22.6404C16.2268 22.7172 15.7212 22.3362 15.6443 21.7893L13.1392 3.96444ZM8 20.6741H11V4.67412H8V20.6741ZM7 2.67412L6.99027 2.67416H2C1.44772 2.67416 1 3.12188 1 3.67417V21.6742C1 22.2264 1.44772 22.6742 2 22.6742H7L7.00967 22.6741H12C12.5523 22.6741 13 22.2264 13 21.6741V3.67412C13 3.12183 12.5523 2.67412 12 2.67412H7ZM3 4.67416H6V20.6742H3V4.67416Z" 
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}