import classNames from 'classnames';
import { useTranslation } from 'next-i18next';

import { CinemaDto } from '@cineamo/legacy-frontend-lib/src/models/cinema/CinemaDto.types';

import { getCinemaById } from '@cineamo/legacy-frontend-lib/src/api/cinema/cinema-api';

import { getCountryFromCode } from '@cineamo/legacy-frontend-lib/src/helper/international-helper';
import { mapLinkDefault, mapLinkMobile } from '@cineamo/legacy-frontend-lib/src/helper/LocationHelper';

import { IconCircleCrossed16 } from '@cineamo/legacy-frontend-lib/src/icons/symbols/ic-circleCrossed';
import { IconStarSolid16 } from '@cineamo/legacy-frontend-lib/src/icons/symbols/ic-starSolid';

export const CINEMA_ID_PLACEHOLDER = 'name-of-cinema';

export function cinemaIdOrSlug(cinema?: CinemaDto): number | string {
    return cinema?.slug || cinema?.id || CINEMA_ID_PLACEHOLDER;
}

export function checkForCinemaPlaceholderOrUndefined(cinemaIdOrSlug: number | string): boolean {
    return !cinemaIdOrSlug || cinemaIdOrSlug === CINEMA_ID_PLACEHOLDER;
}

export function constructCinemaAddress(cinema: CinemaDto): string {
    return `${cinema.street && cinema.street}${cinema.postalCode && `, ${cinema.postalCode}`}
        ${cinema.city && ` ${cinema.city}`}`;
}

export function getCinemaInitials(cinema: CinemaDto): string {
    const cinemaName = cinema?.name || '';
    const initials: string[] = [];
    if (cinemaName.length > 2) {
        let textParts: string[] = cinemaName.split(' ');
        if (textParts.length <= 1) {
            textParts = cinemaName.split('-');
        }
        if (textParts.length >= 1) {
            const firstInitial = textParts[0]?.[0];
            if (firstInitial) {
                initials.push(firstInitial);
            }
            if (textParts.length >= 2) {
                const secondInitial = textParts[1]?.[0];
                if (secondInitial) {
                    initials.push(secondInitial);
                }
            }
        }
    } else if (cinemaName.length > 0) {
        if (cinemaName[0]) {
            initials.push(cinemaName[0]);
        }
        if (cinemaName[1]) {
            initials.push(cinemaName[1]);
        }
    }
    return initials.join('').toUpperCase();
}

export function getCinemaLogo(cinema: CinemaDto, useProfileImageAsFallback = false): string | undefined {
    const logo = cinema?.logoImageUrl;
    if (!logo && useProfileImageAsFallback) {
        return cinema?.profileImageUrl;
    }
    return logo;
}

export function jsxMapLinkMobile(options: { cinema: CinemaDto; locale: string }) {
    const cinema = options.cinema;
    const fullAddressParts: string[] = [];
    const postalCodeCityParts: string[] = [];
    if (cinema.street) {
        fullAddressParts.push(cinema.street);
    }
    if (cinema.postalCode) {
        postalCodeCityParts.push(cinema.postalCode);
    }
    if (cinema.city) {
        postalCodeCityParts.push(cinema.city);
    }
    fullAddressParts.push(postalCodeCityParts.join(' '));
    if (cinema.countryCode) {
        fullAddressParts.push(cinema.countryCode);
    }
    const fullAddress = fullAddressParts.join(', ');
    return (
        <a
            className="flex flex-col"
            href={mapLinkMobile(cinema.street, cinema.postalCode, cinema.city)}>
            <div className="flex flex-row">{fullAddress && <span>{fullAddress}</span>}</div>
        </a>
    );
}

export function jsxMapLinkDefault(options: { cinema: CinemaDto; locale: string }) {
    const cinema = options.cinema;
    const locale = options.locale;
    const fullAddressParts: string[] = [];
    const postalCodeCityParts: string[] = [];
    if (cinema.street) {
        fullAddressParts.push(cinema.street);
    }
    if (cinema.postalCode) {
        postalCodeCityParts.push(cinema.postalCode);
    }
    if (cinema.city) {
        postalCodeCityParts.push(cinema.city);
    }
    fullAddressParts.push(postalCodeCityParts.join(' '));

    const country = cinema.countryCode ? getCountryFromCode(cinema.countryCode, locale) : null;
    if (country) {
        fullAddressParts.push(country);
    }
    const fullAddress = fullAddressParts.join(', ');
    return (
        <a
            className="flex flex-col"
            href={mapLinkDefault(cinema.street, cinema.postalCode, cinema.city)}>
            <div className="flex flex-row">{fullAddress && <span>{fullAddress}</span>}</div>
        </a>
    );
}

export function getDeterministicColorFromString(
    text: string,
    options?: {
        minLuminance: number;
    }
): string {
    let hash = 0;
    for (let i = 0; i < text.length; i++) {
        const char = text.charCodeAt(i);
        hash = char + ((hash << 5) - hash);
    }

    let r = hash & 0xff;
    let g = (hash >> 8) & 0xff;
    let b = (hash >> 16) & 0xff;

    const luminance = 0.299 * r + 0.587 * g + 0.114 * b;

    const minLuminance = options?.minLuminance;
    if (luminance < minLuminance) {
        const factor = minLuminance / luminance;
        r = Math.min(255, r * factor);
        g = Math.min(255, g * factor);
        b = Math.min(255, b * factor);
    }

    return `#${((r << 16) | (g << 8) | b).toString(16).padStart(6, '0')}`;
}

type CinemaRequestSettings = {
    status: string;
    icon: JSX.Element;
    color: string;
};

type CinemaStatusProps = {
    cinema: CinemaDto;
};

function getCinemaRequestSettings(cinema: CinemaDto): CinemaRequestSettings {
    const config = cinema._embedded?.cinemaConfiguration;
    if (!config) {
        return null;
    }
    const isPrivateBookingEnabled = config.isPrivateBookingEnabled == true;
    const isProgramSuggestionEnabled = config.isProgramSuggestionEnabled == true;
    let cinemaRequestSettings: CinemaRequestSettings = null;
    if (!isPrivateBookingEnabled && !isProgramSuggestionEnabled) {
        cinemaRequestSettings = {
            status: 'cinema.status.bookings-temporarily-unavailable',
            icon: <IconCircleCrossed16 />,
            color: 'text-red'
        };
    } else if (isPrivateBookingEnabled && isProgramSuggestionEnabled) {
        cinemaRequestSettings = {
            status: 'cinema.status.premium-partner',
            icon: <IconStarSolid16 />,
            color: 'text-cyan-dark'
        };
    }
    return cinemaRequestSettings;
}

export function CinemaRequestStatus(props: CinemaStatusProps): JSX.Element {
    const { t } = useTranslation();
    const cinemaRequestSettings = getCinemaRequestSettings(props.cinema);

    if (!cinemaRequestSettings) {
        return null;
    }

    return (
        <div className={classNames('flex flex-row items-center space-x-4', cinemaRequestSettings.color)}>
            {cinemaRequestSettings.icon}
            <span className="text-body-bold-10">{t(cinemaRequestSettings.status)}</span>
        </div>
    );
}

export function isCinemaRequestDisabled(cinema: CinemaDto): boolean | null {
    const config = cinema._embedded?.cinemaConfiguration;
    if (!config) {
        return null;
    }
    const isPrivateBookingEnabled = config.isPrivateBookingEnabled == true;
    const isProgramSuggestionEnabled = config.isProgramSuggestionEnabled == true;
    return !isPrivateBookingEnabled && !isProgramSuggestionEnabled;
}

export async function getCinemasByIds(ids: number[]): Promise<CinemaDto[]> {
    const requests = ids.map((id) => getCinemaById(id));
    return await Promise.all(requests);
}
