import { useTranslation } from 'next-i18next';
import { useRouter } from 'next-translate-routes';
import { useState } from 'react';

import CinemaPickerAdminApp from '@/components/pages/cinema/CinemaPickerAdminApp';
import NotificationsInDropDown from '@/components/ui/notification-dropdown/NotificationsInDropDown';
import Breadcrumbs from '@cineamo/legacy-frontend-lib/src/components/ui/navigation/Breadcrumbs';

import { Role } from '@cineamo/legacy-frontend-lib/src/constants/role-constants';

import { useCinemaStore } from '@cineamo/legacy-frontend-lib/src/store/cinema/cinemaStore';
import { useNotificationsStore } from '@cineamo/legacy-frontend-lib/src/store/notifications/notificationsStore';
import { useUserStore } from '@cineamo/legacy-frontend-lib/src/store/user/userStore';

import { IconBell24 } from '@cineamo/legacy-frontend-lib/src/icons/ic-bell';
import { IconRingingBell24 } from '@cineamo/legacy-frontend-lib/src/icons/ic-ringingBell';

type TopNavigationProps = { pageProps: Record<string, unknown> };

function NavigationBar(props: TopNavigationProps) {
    const { t } = useTranslation('common');

    const { user, userType } = useUserStore();

    const { unreadNotificationsCount } = useNotificationsStore();

    const [showNotifications, setShowNotifications] = useState(false);

    const { cinema } = useCinemaStore();

    const router = useRouter();

    const cinemaId = router.asPath.split('/')[1];

    const isCinemaSlugInRouter = cinemaId === cinema?.slug;

    const currentCinema = (): JSX.Element => {
        if (!(userType.includes(Role.ROLE_CINEMA) || userType.includes(Role.ROLE_ADMIN))) {
            return;
        }

        return (
            <div className="flex-auto lg:flex-none ml-auto">
                <CinemaPickerAdminApp t={t} />
            </div>
        );
    };

    const notificationsByUserRole = (): JSX.Element => {
        // TODO: Show top navigation items depending on the user role
        if (user?.roles?.length <= 0) return;

        return (
            <div className="relative">
                <div
                    className="flex-none clickable-element"
                    onClick={() => setShowNotifications(!showNotifications)}>
                    {unreadNotificationsCount > 0 ? <IconRingingBell24 /> : <IconBell24 />}
                </div>
                {showNotifications && (
                    <NotificationsInDropDown
                        setShowNotifications={setShowNotifications}
                        showNotifications={showNotifications}
                        user={user}
                        t={t}
                    />
                )}
            </div>
        );
    };

    return (
        <div className="p-16 text-white flex flex-row flex-nowrap border-b-1 border-white border-opacity-20 space-x-24">
            <div className="hidden lg:block flex-auto">
                <Breadcrumbs
                    pageProps={props.pageProps}
                    hideRootBreadcrumb={isCinemaSlugInRouter}
                />
            </div>

            {currentCinema()}

            {notificationsByUserRole()}
        </div>
    );
}

export default NavigationBar;
