import { useRouter } from 'next-translate-routes';
import React, { ReactNode, useEffect } from 'react';
import { useQuery } from 'react-query';

import SidebarNavigationLayout from '@/components/layouts/sub-layouts/SidebarNavigationLayout';
import SidebarNavigation from '@/components/navigation/SidebarNavigation';
import CineamoTrailerConfirmModal from '@/components/ui/modal/CineamoTrailerConfirmModal';

import { getMessages } from '@cineamo/legacy-frontend-lib/src/api/message/message-api';

import { apiResponseHandler } from '@cineamo/legacy-frontend-lib/src/helper/api-response-helper/ApiResponseHelper';

import useLastSelectedCinema, { updateCinemaInUrl } from '@/src/hooks/useLastSelectedCinema';

import { useNotificationsStore } from '@cineamo/legacy-frontend-lib/src/store/notifications/notificationsStore';
import { useUserStore } from '@cineamo/legacy-frontend-lib/src/store/user/userStore';

export type BasicLayoutProps = {
    children?: ReactNode;
    pageProps: Record<string, unknown>;
};

function BasicLayout(props: BasicLayoutProps): JSX.Element {
    const { children } = props;

    const { setNotifications } = useNotificationsStore();

    const router = useRouter();
    const { user } = useUserStore();
    const { lastSelectedCinema: cinema } = useLastSelectedCinema(user);
    const [isSidebarCollapsed, setIsSidebarCollapsed] = React.useState(false);

    useQuery(['messages', user], async () => {
        const response = await getMessages({ userId: user.id });
        apiResponseHandler(response, (successResponse) => setNotifications(successResponse._embedded.messages));
    });

    useEffect(() => {
        updateCinemaInUrl(router, cinema);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <CineamoTrailerConfirmModal />
            <div className="h-[100dvh] flex flex-col font-montserrat-regular tracking-regular">
                <SidebarNavigationLayout
                    isSidebarCollapsed={isSidebarCollapsed}
                    setIsSidebarCollapsed={setIsSidebarCollapsed}
                    pageProps={props.pageProps}
                    nav={
                        <SidebarNavigation
                            isSideBarCollapsed={isSidebarCollapsed}
                            setIsSidebarCollapsed={setIsSidebarCollapsed}
                        />
                    }
                    content={children}
                />
            </div>
        </>
    );
}

export default BasicLayout;
