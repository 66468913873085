import { TFunction, useTranslation } from 'next-i18next';
import Image from 'next/image';
import { NextRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

import Button, { ButtonStyle } from '@cineamo/legacy-frontend-lib/src/components/ui/buttons/Button';

import { CinemaDto } from '@cineamo/legacy-frontend-lib/src/models/cinema/CinemaDto.types';

import changeCinemaRedirect from '@/src/helper/changeCinemaRedirect';
import { loadAsset } from '@cineamo/legacy-frontend-lib/src/helper/asset-helper';

const defaultCinemaImage = loadAsset('/images/placeholders/im-cinemaDummy.png');

export type FavoriteCinemaListItemProps = {
    cinema: CinemaDto;
    onSetCinema: (cinema?: CinemaDto) => void;
    router: NextRouter;
    showLoadingAnimation: () => void;
    hideLoadingAnimation: () => void;
    t: TFunction;
};

function FavoriteCinemaListItem(props: FavoriteCinemaListItemProps) {
    const { cinema, onSetCinema, router, showLoadingAnimation, hideLoadingAnimation } = props;

    const t = props.t || useTranslation('navigation').t;

    const [error, setError] = useState(false);

    useEffect(() => {
        setError(false);
    }, [cinema]);

    return (
        <li
            className="favorite-cinema-list-item px-16 py-8 rounded-4 hover:bg-darkBlue group transition-colors duration-100 hover:bg-opacity-20"
            key={`cinema#${cinema.id}`}>
            <div className="w-full flex flex-row items-center">
                <div className="relative w-48 h-48 flex-none">
                    <Image
                        layout="fill"
                        className="rounded-full object-cover"
                        objectFit="cover"
                        src={!error && cinema?.profileImageUrl ? cinema.profileImageUrl : defaultCinemaImage}
                        onError={() => setError(true)}
                        onErrorCapture={() => setError(true)}
                        alt="user image"
                    />
                </div>
                <span className="font-montserrat-bold mx-16">{`${cinema.name}`}</span>
                <Button
                    className="font-montserrat-semibold ml-auto opacity-100 md:opacity-0 transition-opacity duration-100
                                            group-hover:opacity-100 cursor-pointer text-darkBlue outline-none"
                    style={ButtonStyle.frameless}
                    onClick={() => {
                        if (onSetCinema) {
                            onSetCinema(cinema);
                        }

                        changeCinemaRedirect(router, cinema, showLoadingAnimation, hideLoadingAnimation);
                    }}>
                    {t('navigation:cinema-picker.select-cinema')}
                </Button>
            </div>
        </li>
    );
}

export default FavoriteCinemaListItem;
