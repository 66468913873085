import {CustomSVGProps} from '../types/CustomSVGProps.types';

export function IconPricing48(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
                  fill={props.fill || 'currentColor'}/>
            <path
                d="M16 14.2638C16 15.0681 15.7138 15.7532 15.1413 16.3191C14.5784 16.8752 13.7579 17.2128 12.6798 17.3319V19H11.5063V17.3468C10.8002 17.2972 10.1324 17.1631 9.50268 16.9447C8.87299 16.7262 8.37209 16.4482 8 16.1106L8.64401 14.6064C8.99702 14.9043 9.42636 15.1574 9.93202 15.366C10.4377 15.5645 10.9624 15.6887 11.5063 15.7383V12.7596C10.8289 12.5908 10.2612 12.4071 9.80322 12.2085C9.3548 12 8.97794 11.7021 8.67263 11.3149C8.36732 10.9177 8.21467 10.3965 8.21467 9.75106C8.21467 8.94681 8.49612 8.2617 9.05903 7.69574C9.62194 7.12979 10.4377 6.7922 11.5063 6.68298V5H12.6798V6.65319C13.2141 6.68298 13.734 6.7773 14.2397 6.93617C14.7549 7.09503 15.2033 7.30355 15.585 7.5617L14.9982 9.06596C14.2731 8.62908 13.5003 8.36099 12.6798 8.2617V11.2702C13.3667 11.439 13.9344 11.6227 14.3828 11.8213C14.8408 12.0199 15.2224 12.3177 15.5277 12.7149C15.8426 13.1021 16 13.6184 16 14.2638ZM10.0608 9.6617C10.0608 9.98936 10.1849 10.2525 10.4329 10.4511C10.681 10.6496 11.0388 10.8184 11.5063 10.9574V8.30638C11.0197 8.38582 10.6571 8.54468 10.4186 8.78298C10.1801 9.02128 10.0608 9.31418 10.0608 9.6617ZM12.6798 15.7085C13.1855 15.6291 13.5575 15.4752 13.7961 15.2468C14.0441 15.0184 14.1682 14.7355 14.1682 14.3979C14.1682 14.0603 14.0394 13.7922 13.7818 13.5936C13.5241 13.3851 13.1568 13.2113 12.6798 13.0723V15.7085Z"
                fill={props.fill || 'currentColor'}/>
        </svg>
    );
}

export function IconPricing24(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
                  fill={props.fill || 'currentColor'}/>
            <path
                d="M16 14.2638C16 15.0681 15.7138 15.7532 15.1413 16.3191C14.5784 16.8752 13.7579 17.2128 12.6798 17.3319V19H11.5063V17.3468C10.8002 17.2972 10.1324 17.1631 9.50268 16.9447C8.87299 16.7262 8.37209 16.4482 8 16.1106L8.64401 14.6064C8.99702 14.9043 9.42636 15.1574 9.93202 15.366C10.4377 15.5645 10.9624 15.6887 11.5063 15.7383V12.7596C10.8289 12.5908 10.2612 12.4071 9.80322 12.2085C9.3548 12 8.97794 11.7021 8.67263 11.3149C8.36732 10.9177 8.21467 10.3965 8.21467 9.75106C8.21467 8.94681 8.49612 8.2617 9.05903 7.69574C9.62194 7.12979 10.4377 6.7922 11.5063 6.68298V5H12.6798V6.65319C13.2141 6.68298 13.734 6.7773 14.2397 6.93617C14.7549 7.09503 15.2033 7.30355 15.585 7.5617L14.9982 9.06596C14.2731 8.62908 13.5003 8.36099 12.6798 8.2617V11.2702C13.3667 11.439 13.9344 11.6227 14.3828 11.8213C14.8408 12.0199 15.2224 12.3177 15.5277 12.7149C15.8426 13.1021 16 13.6184 16 14.2638ZM10.0608 9.6617C10.0608 9.98936 10.1849 10.2525 10.4329 10.4511C10.681 10.6496 11.0388 10.8184 11.5063 10.9574V8.30638C11.0197 8.38582 10.6571 8.54468 10.4186 8.78298C10.1801 9.02128 10.0608 9.31418 10.0608 9.6617ZM12.6798 15.7085C13.1855 15.6291 13.5575 15.4752 13.7961 15.2468C14.0441 15.0184 14.1682 14.7355 14.1682 14.3979C14.1682 14.0603 14.0394 13.7922 13.7818 13.5936C13.5241 13.3851 13.1568 13.2113 12.6798 13.0723V15.7085Z"
                fill={props.fill || 'currentColor'}/>
        </svg>
    );
}

export function IconPricing16(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
                  fill={props.fill || 'currentColor'}/>
            <path
                d="M16 14.2638C16 15.0681 15.7138 15.7532 15.1413 16.3191C14.5784 16.8752 13.7579 17.2128 12.6798 17.3319V19H11.5063V17.3468C10.8002 17.2972 10.1324 17.1631 9.50268 16.9447C8.87299 16.7262 8.37209 16.4482 8 16.1106L8.64401 14.6064C8.99702 14.9043 9.42636 15.1574 9.93202 15.366C10.4377 15.5645 10.9624 15.6887 11.5063 15.7383V12.7596C10.8289 12.5908 10.2612 12.4071 9.80322 12.2085C9.3548 12 8.97794 11.7021 8.67263 11.3149C8.36732 10.9177 8.21467 10.3965 8.21467 9.75106C8.21467 8.94681 8.49612 8.2617 9.05903 7.69574C9.62194 7.12979 10.4377 6.7922 11.5063 6.68298V5H12.6798V6.65319C13.2141 6.68298 13.734 6.7773 14.2397 6.93617C14.7549 7.09503 15.2033 7.30355 15.585 7.5617L14.9982 9.06596C14.2731 8.62908 13.5003 8.36099 12.6798 8.2617V11.2702C13.3667 11.439 13.9344 11.6227 14.3828 11.8213C14.8408 12.0199 15.2224 12.3177 15.5277 12.7149C15.8426 13.1021 16 13.6184 16 14.2638ZM10.0608 9.6617C10.0608 9.98936 10.1849 10.2525 10.4329 10.4511C10.681 10.6496 11.0388 10.8184 11.5063 10.9574V8.30638C11.0197 8.38582 10.6571 8.54468 10.4186 8.78298C10.1801 9.02128 10.0608 9.31418 10.0608 9.6617ZM12.6798 15.7085C13.1855 15.6291 13.5575 15.4752 13.7961 15.2468C14.0441 15.0184 14.1682 14.7355 14.1682 14.3979C14.1682 14.0603 14.0394 13.7922 13.7818 13.5936C13.5241 13.3851 13.1568 13.2113 12.6798 13.0723V15.7085Z"
                fill={props.fill || 'currentColor'}/>
        </svg>
    );
}