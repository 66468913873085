import { Link } from 'next-translate-routes';
import { Url } from 'next-translate-routes/types';
import React, { HTMLAttributeAnchorTarget } from 'react';

export type LinkWithStyleProps = {
    children?: React.ReactNode;
    className?: string;
    href?: string | Url;
    as?: string;
    onClick?: (event) => void;
    target?: HTMLAttributeAnchorTarget;
    titleOnHover?: string;
};

function LinkWithStyle(props: LinkWithStyleProps): React.JSX.Element {
    const { children, className, href, onClick, target, titleOnHover } = props;

    function onClickInternal(e) {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        if (onClick) {
            onClick(e);
        }
    }

    function linkWithHref() {
        return (
            <Link
                href={href}
                passHref>
                <a
                    className={`${className || ''}`}
                    title={titleOnHover || undefined}
                    onClick={onClickInternal}
                    target={target}>
                    {children}
                </a>
            </Link>
        );
    }

    function tagWithOnClick() {
        return (
            <a
                className={`${className || ''}`}
                href={href?.toString()}
                target={target || undefined}
                title={titleOnHover || undefined}
                onClick={onClickInternal}>
                {children}
            </a>
        );
    }

    return (
        <>
            {href
                ? typeof href === 'string'
                    ? href.startsWith('/')
                        ? linkWithHref()
                        : tagWithOnClick()
                    : linkWithHref()
                : tagWithOnClick()}
        </>
    );
}

export default LinkWithStyle;
