import React, { useEffect, useRef, useState } from 'react';

import { IconClose16, IconClose24 } from '../../../icons/menu/ic-close';
import { IconSearch16, IconSearch24 } from '../../../icons/menu/ic-search';
import ButtonWithIcon from '../buttons/ButtonWithIcon';
import BasicInput, { BasicInputProps } from '../input/BasicInput';

export type SearchBarProps<TFieldName> = {
    id?: string;
    className?: string;
    basicInputProps?: BasicInputProps<TFieldName>;
    label?: string;
    placeholder?: string;
    value?: string;
    leadingAction?: () => void;
    leadingActionIcon?: string | JSX.Element | StaticImageData;
    onChange?: (value) => void;
    onFocus?: (state: boolean) => void;
    onBlur?: () => void;
    onInputClick?: () => void;
    searchInputRef?;
    customIcon?: JSX.Element;
    trailingIconSize?: '16' | '24';
    trailingIconColor?: string;
    showInput?: boolean;
};

function SearchBar<TFieldName>(
    props: SearchBarProps<TFieldName>,
    ref: React.LegacyRef<HTMLDivElement> | undefined
): JSX.Element {
    const [lastSearchTimeout, setLastSearchTimeout] = useState(null);
    const [animateIcon, setAnimateIcon] = useState(false);
    const [currentValue, setCurrentValue] = useState(props.value ?? undefined);

    const searchInputRef = props.searchInputRef || useRef(null);

    function onSearchInput(input) {
        setCurrentValue(input);
        setAnimateIcon(input && input.length > 0);

        if (lastSearchTimeout !== null) {
            clearInterval(lastSearchTimeout);
            setLastSearchTimeout(null);
        }

        const timeOut = setInterval(() => {
            if (props.onChange) {
                props.onChange(input);
            }
            clearInterval(timeOut);
        }, 300);
        setLastSearchTimeout(timeOut);
    }

    function onLeadingIconClick() {
        if (props.leadingAction) {
            props.leadingAction();
        }
    }

    function onTrailingIconClick() {
        if (searchInputRef && searchInputRef.current) {
            searchInputRef.current.value = null;
        }
    }

    function trailingIcon(): JSX.Element {
        const iconSearchClassName = `transform duration-150 ${animateIcon ? 'rotate-180 opacity-0' : ''} ${
            props.trailingIconColor
        }`;
        const iconCloseClassName = `absolute top-0 transform duration-150 ${animateIcon ? 'rotate-180' : 'opacity-0'} ${
            props.trailingIconColor
        }`;

        const size16: JSX.Element = (
            <div className="relative">
                <IconSearch16 className={iconSearchClassName} />
                <IconClose16 className={iconCloseClassName} />
            </div>
        );

        const size24: JSX.Element = (
            <div className="relative">
                <IconSearch24 className={iconSearchClassName} />
                <IconClose24 className={iconCloseClassName} />
            </div>
        );

        switch (props.trailingIconSize) {
            case '16':
                return size16;
            case '24':
                return size24;
            default:
                return size16;
        }
    }
    function handleClickOutside(event: Event) {
        if (!searchInputRef?.current?.contains(event.target)) {
            setAnimateIcon(false);
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div
            id={props.id}
            className={`${props.className} flex flex-row space-x-8`}
            ref={ref}
            onClick={props.onInputClick}>
            {props.leadingAction && (
                <ButtonWithIcon
                    icon={props.leadingActionIcon}
                    onClick={onLeadingIconClick}
                    type="button"
                />
            )}
            <BasicInput
                {...props.basicInputProps}
                className="hide-search-input-clear-button"
                value={currentValue}
                label={props.label}
                title={props.placeholder}
                placeholder={props.placeholder}
                trailingIcon={props.customIcon ? props.customIcon : trailingIcon()}
                trailingIconAction={onTrailingIconClick}
                onChange={onSearchInput}
                onFocusChange={(state) => props.onFocus && props.onFocus(state)}
                type="search"
                inputRef={searchInputRef}
                onBlur={props.onBlur}
            />
        </div>
    );
}

export default React.forwardRef(SearchBar);
