import classnames from 'classnames';
import React from 'react';

import { IconLoading16 } from '../../../icons/ic-loading';
import Button, { ButtonProps, ButtonStyle } from '../buttons/Button';

export type TwoButtonComponentProps = {
    className?: string;
    primaryLabel: string;
    primaryAction?: () => void;
    primaryButtonProps?: ButtonProps;
    secondaryLabel: string;
    secondaryAction?: () => void;
    secondaryButtonProps?: ButtonProps;
    isProcessing?: boolean;
    stretched?: boolean;
};

function TwoButtonComponent(props: TwoButtonComponentProps): JSX.Element {
    const {
        className,
        primaryLabel,
        primaryAction,
        primaryButtonProps,
        secondaryLabel,
        secondaryAction,
        secondaryButtonProps,
        isProcessing,
        stretched
    } = props;

    return (
        <div
            className={classnames(
                className,
                'flex flex-col lg:flex-row space-y-12 lg:space-y-0 lg:space-x-16 w-full h-auto lg:items-center',
                { 'justify-end': !stretched }
            )}>
            <Button
                style={secondaryButtonProps?.style || ButtonStyle.inverted_on_white_bg}
                disabled={isProcessing}
                onClick={() => {
                    if (secondaryAction) secondaryAction();
                }}
                {...secondaryButtonProps}
                className={classnames(secondaryButtonProps?.className, 'h-48 md:h-auto', {
                    'lg:w-0 lg:flex-auto': stretched
                })}>
                {secondaryLabel}
            </Button>

            <Button
                style={primaryButtonProps?.style || ButtonStyle.default_on_white_bg}
                leadingIcon={isProcessing && <IconLoading16 className="animate-spin" />}
                disabled={isProcessing}
                onClick={() => {
                    if (primaryAction) primaryAction();
                }}
                {...primaryButtonProps}
                className={classnames(primaryButtonProps?.className, 'h-48 md:h-auto', {
                    'lg:w-0 lg:flex-auto': stretched
                })}>
                {primaryLabel}
            </Button>
        </div>
    );
}

export default TwoButtonComponent;
