import { TFunction, useTranslation } from 'next-i18next';
import { useRouter } from 'next-translate-routes';
import React, { useEffect, useRef, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import CinemaPickerDropdown from '@/components/pages/cinema/CinemaPickerDropdown';
import CinemaProfileImageRound from '@/components/ui/image/CinemaProfileImageRound';
import NoCinemaSelectedModal from '@/components/ui/modal/NoCinemaSelectedModal';

import { CinemaDto } from '@cineamo/legacy-frontend-lib/src/models/cinema/CinemaDto.types';

import { Role } from '@cineamo/legacy-frontend-lib/src/constants/role-constants';

import { getCinemaById, getCinemas } from '@cineamo/legacy-frontend-lib/src/api/cinema/cinema-api';
import { CinemaApiEmbed } from '@cineamo/legacy-frontend-lib/src/api/cinema/cinema-api.embed';

import useLastSelectedCinema from '@/src/hooks/useLastSelectedCinema';

import { useUserStore } from '@cineamo/legacy-frontend-lib/src/store/user/userStore';

import { IconDown16 } from '@cineamo/legacy-frontend-lib/src/icons/arrows/ic-down';

export type CinemaPickerProps = {
    cinemaProp?: CinemaDto;
    t: TFunction;
};

function CinemaPickerAdminApp(props: CinemaPickerProps): JSX.Element {
    const t = props.t || useTranslation('navigation').t;

    const router = useRouter();

    const { user, userType } = useUserStore();

    const { cinemaProp } = props;

    const { lastSelectedCinema, setLastSelectedCinema } = useLastSelectedCinema(user, router);
    const currentCinema: CinemaDto = cinemaProp || lastSelectedCinema;

    const cinemaDropdownRef = useRef<HTMLDivElement | null>(null);
    const [openCinemaPickerDropdown, setOpenCinemaPickerDropdown] = useState<boolean>(false);
    const pickerRef = useRef(null);

    const [noCinemaSelectedModalIsOpen, setNoCinemaSelectedModalIsOpen] = useState(false);
    const [query, setQuery] = useState<string>();
    const [page, setPage] = useState(1);

    function handleClickOutside(event) {
        if (pickerRef.current && !pickerRef.current.contains(event.target)) {
            setOpenCinemaPickerDropdown(false);
            setQuery(undefined);
            setPage(1);
        }
    }

    function onSearchCinema(value: string) {
        setPage(1);
        setQuery(value);
    }

    function selectCinemaHandler(cinema: CinemaDto) {
        setLastSelectedCinema(cinema);
        setOpenCinemaPickerDropdown(false);
        setNoCinemaSelectedModalIsOpen(false);
    }

    function onSignOutFromCinemaClick() {
        setLastSelectedCinema(undefined);
        if (!userType.includes(Role.ROLE_ADMIN)) {
            setNoCinemaSelectedModalIsOpen(true);
        }
    }

    useEffect(() => {
        if (document && typeof window !== 'undefined') {
            document.addEventListener('mousedown', handleClickOutside);
        }
        return () => {
            if (document && typeof window !== 'undefined') {
                document.removeEventListener('mousedown', handleClickOutside);
            }
        };
    }, []);

    useQuery(
        ['selected-cinema', lastSelectedCinema],
        async () => {
            if (!lastSelectedCinema) {
                return null;
            }
            const res = await getCinemaById(lastSelectedCinema.id);
            setLastSelectedCinema(res);
            return res;
        },
        {
            staleTime: 1000 * 60, // 1 Minute
            refetchInterval: 1000 * 60, // 1 Minute
            refetchOnWindowFocus: false
        }
    );

    const {
        data: cinemasEmployedAtResponse,
        isLoading: isLoadingCinemasEmployedAt,
        isFetching: isFetchingCinemasEmployedAt
    } = useQuery(
        ['cinemas-employed-at', page, query],
        async () => {
            if (userType.includes(Role.ROLE_ADMIN)) {
                return await getCinemas({
                    embed: [CinemaApiEmbed.CINEMA_CONFIGURATION],
                    page: page,
                    query: query
                });
            }
            return await getCinemas({
                employedUserId: user.id,
                embed: [CinemaApiEmbed.CINEMA_CONFIGURATION],
                page: page,
                query: query
            });
        },
        {
            keepPreviousData: true,
            refetchInterval: false,
            refetchIntervalInBackground: false,
            refetchOnWindowFocus: false
        }
    );

    const queryClient = useQueryClient();

    useEffect(() => {
        if (cinemasEmployedAtResponse && cinemasEmployedAtResponse._page_count > 1) {
            queryClient
                .prefetchQuery(['cinemas-employed-at', page + 1], () => {
                    if (userType.includes(Role.ROLE_ADMIN)) {
                        return getCinemas({
                            embed: [CinemaApiEmbed.CINEMA_CONFIGURATION],
                            page: page + 1,
                            query: query
                        });
                    }
                    return getCinemas({
                        employedUserId: user.id,
                        embed: [CinemaApiEmbed.CINEMA_CONFIGURATION],
                        page: page + 1,
                        query: query
                    });
                })
                .then();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cinemasEmployedAtResponse, page, queryClient]);

    useEffect(() => {
        const hasLastSelectedCinema = !!lastSelectedCinema;
        if (
            !userType.includes(Role.ROLE_ADMIN) &&
            !hasLastSelectedCinema &&
            cinemasEmployedAtResponse?._embedded?.cinemas?.length === 1
        ) {
            selectCinemaHandler(cinemasEmployedAtResponse._embedded.cinemas[0]);
        } else if (!hasLastSelectedCinema && !userType.includes(Role.ROLE_ADMIN)) {
            setNoCinemaSelectedModalIsOpen(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cinemasEmployedAtResponse]);

    if (!(userType.includes(Role.ROLE_CINEMA) || userType.includes(Role.ROLE_ADMIN))) {
        return null;
    }

    return (
        <>
            <NoCinemaSelectedModal
                modalIsOpen={noCinemaSelectedModalIsOpen}
                setModalIsOpen={setNoCinemaSelectedModalIsOpen}
                onSetCinema={selectCinemaHandler}
                t={t}
            />

            {userType.includes(Role.ROLE_ADMIN) || cinemasEmployedAtResponse?._total_items > 1 ? (
                <div ref={pickerRef}>
                    <div className="relative z-40 max-h-full">
                        <div
                            className={`cursor-pointer $"" ${openCinemaPickerDropdown ? 'flex' : 'flex'}`}
                            ref={cinemaDropdownRef}
                            onClick={() => {
                                setOpenCinemaPickerDropdown(!openCinemaPickerDropdown);
                            }}>
                            <div className="w-full flex flex-row my-auto space-x-12 items-center">
                                <CinemaProfileImageRound
                                    size="24"
                                    cinema={currentCinema}
                                />
                                <h1 className="w-full text-body-bold-16 text-white font-montserrat-bold whitespace-nowrap overflow-ellipsis overflow-hidden">
                                    {currentCinema ? currentCinema?.name : t('navigation:cinema-picker.choose-cinema')}
                                </h1>
                                <IconDown16
                                    className={`flex-none ${
                                        openCinemaPickerDropdown ? '-rotate-180' : ''
                                    } transition duration-200 transform`}
                                />
                            </div>
                        </div>

                        <CinemaPickerDropdown
                            open={openCinemaPickerDropdown}
                            setOpen={setOpenCinemaPickerDropdown}
                            currentCinema={currentCinema}
                            onSearchCinema={onSearchCinema}
                            query={query}
                            page={page}
                            setPage={setPage}
                            cinemasEmployedAtResponse={cinemasEmployedAtResponse}
                            isLoadingCinemasEmployedAt={isLoadingCinemasEmployedAt}
                            isFetchingCinemasEmployedAt={isFetchingCinemasEmployedAt}
                            selectCinemaHandler={selectCinemaHandler}
                            onSignOutFromCinemaClick={onSignOutFromCinemaClick}
                        />
                    </div>
                </div>
            ) : (
                <div className="relative z-40 max-h-full">
                    <div className="w-full flex flex-row space-x-12 items-center">
                        <CinemaProfileImageRound
                            size="24"
                            cinema={currentCinema}
                        />
                        <h1 className="w-full text-body-bold-16 text-white font-montserrat-semibold whitespace-nowrap overflow-ellipsis overflow-hidden">
                            {currentCinema ? currentCinema?.name : t('navigation:cinema-picker.choose-cinema')}
                        </h1>
                    </div>
                </div>
            )}
        </>
    );
}

export default CinemaPickerAdminApp;
