import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { useQuery } from 'react-query';

import FavoriteCinemaListItem from '@/components/pages/cinema/FavoriteCinemaListItem';
import ListLoadingSpinner from '@cineamo/legacy-frontend-lib/src/components/ui/animation/ListLoadingSpinner';
import { BasicInputStyle } from '@cineamo/legacy-frontend-lib/src/components/ui/input/BasicInput';
import SearchBar from '@cineamo/legacy-frontend-lib/src/components/ui/input/SearchBar';
import Modal, { ModalProps } from '@cineamo/legacy-frontend-lib/src/components/ui/modal/Modal';
import TmpPaginator from '@cineamo/legacy-frontend-lib/src/components/ui/table/TmpPaginator';

import { CinemaDto } from '@cineamo/legacy-frontend-lib/src/models/cinema/CinemaDto.types';

import { Role } from '@cineamo/legacy-frontend-lib/src/constants/role-constants';

import { getCinemas } from '@cineamo/legacy-frontend-lib/src/api/cinema/cinema-api';

import { styleFaded, StyleFadeDirection } from '@cineamo/legacy-frontend-lib/src/helper/styleHelper';

import { useCinemaStore } from '@cineamo/legacy-frontend-lib/src/store/cinema/cinemaStore';
import { useLoadingAnimation } from '@cineamo/legacy-frontend-lib/src/store/loading-animation-context';
import { useUserStore } from '@cineamo/legacy-frontend-lib/src/store/user/userStore';

export type NoCinemaSelectedModalContentProps = ModalProps & {
    onSetCinema: (cinema?: CinemaDto) => void;
};

function NoCinemaSelectedModalContent(props: NoCinemaSelectedModalContentProps) {
    const { onSetCinema } = props;

    const t = props.t || useTranslation('navigation').t;

    const { showLoadingAnimation, hideLoadingAnimation } = useLoadingAnimation();

    const { user, userType } = useUserStore();

    const [query, setQuery] = useState<string>();
    const [page, setPage] = useState<number>(1);

    const router = useRouter();

    const { data: cinemasResponse, isLoading: isLoadingCinemas } = useQuery(
        ['cinema-select-modal', page, query],
        async () => {
            if (userType.includes(Role.ROLE_ADMIN)) {
                return getCinemas({ page, query });
            }
            return getCinemas({ page, query, employedUserId: user.id });
        },
        {
            keepPreviousData: true,
            staleTime: 1000 * 60,
            refetchOnMount: false,
            refetchOnReconnect: false,
            refetchInterval: false,
            refetchOnWindowFocus: false
        }
    );

    function onSearchCinema(value: string) {
        setPage(1);
        setQuery(value);
    }

    return (
        <div className="flex-auto flex flex-col h-full overflow-y-hidden my-12">
            <SearchBar
                className="mx-8 mb-12"
                basicInputProps={{
                    style: BasicInputStyle.gray
                }}
                value={query}
                placeholder={t('navigation:cinema-picker.search-for-cinema')}
                onChange={onSearchCinema}
            />

            <div
                className="cinemas-employed-at-list-container flex flex-col space-y-24 h-auto flex-auto overflow-y-auto"
                style={styleFaded(StyleFadeDirection.Vertical, 10)}>
                {cinemasResponse?._embedded?.cinemas?.map((cinema, index) => (
                    <FavoriteCinemaListItem
                        key={`cinemaEmployedAt#${index}`}
                        cinema={cinema}
                        onSetCinema={onSetCinema}
                        router={router}
                        showLoadingAnimation={showLoadingAnimation}
                        hideLoadingAnimation={hideLoadingAnimation}
                        t={t}
                    />
                ))}

                {!isLoadingCinemas && (!cinemasResponse || cinemasResponse._total_items <= 0) && (
                    <span className="px-14">{t('navigation:cinema-picker.no-cinema-in-list')}</span>
                )}

                {isLoadingCinemas && <ListLoadingSpinner dotClass="bg-darkBlue" />}
            </div>
            <TmpPaginator
                page={page}
                setPage={setPage}
                totalItems={cinemasResponse?._total_items}
                pageCount={cinemasResponse?._page_count}
            />
        </div>
    );
}

export type NoCinemaSelectedModalProps = ModalProps & {
    cinemaList?: CinemaDto[];
    onSetCinema?: (cinema?: CinemaDto) => void;
};

export default function NoCinemaSelectedModal(props: NoCinemaSelectedModalProps): JSX.Element {
    const t = props.t || useTranslation(['navigation', 'common', 'settings']).t;

    const { onSetCinema } = props;

    const { cinema } = useCinemaStore();

    function onSetCinemaInternal(cinema: CinemaDto) {
        if (onSetCinema) {
            onSetCinema(cinema);
        }
    }

    return (
        <Modal
            title={t('navigation:cinema-picker.no-cinema-selected-description')}
            closeable={!!cinema}
            onClose={null}
            {...props}
            t={t}>
            <div className="relative flex flex-col h-full overflow-y-hidden">
                <NoCinemaSelectedModalContent
                    onSetCinema={onSetCinemaInternal}
                    t={t}
                />
            </div>
        </Modal>
    );
}
