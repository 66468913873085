import classnames from 'classnames';
import { useRouter } from 'next-translate-routes';
import React from 'react';

import LinkWithStyle from '@/components/link/LinkWithStyle';

import PermissionsGate, { PermissionGateMode } from '@cineamo/legacy-frontend-lib/src/helper/PermissonsGate';

import { useIsMobile } from '@/src/hooks/useIsMobile';

interface Props {
    className?: string;
    link?: string;
    onClick?: () => void;
    icon?: JSX.Element;
    text: string;
    neededPermission?: string;
    excludedPath?: string;
    isSidebarCollapsed?: boolean;
    setIsSidebarCollapsed?: (isSidebarCollapsed: boolean) => void;
}

function SidebarNavigationItem(props: Props) {
    const {
        isSidebarCollapsed,
        setIsSidebarCollapsed,
        className,
        link,
        onClick,
        icon,
        text,
        neededPermission,
        excludedPath
    } = props;

    const isMobile = useIsMobile();

    const router = useRouter();

    const isIncluded = router.asPath.includes(link);
    const isNotExcludedPath = router.pathname !== excludedPath;

    function collapseSidebar() {
        if (isSidebarCollapsed) {
            setIsSidebarCollapsed(false);
        }
    }

    return (
        <div className={classnames(className)}>
            <PermissionsGate
                necessaryPermission={neededPermission}
                mode={PermissionGateMode.hide}>
                <div
                    className="relative"
                    id={text}>
                    <LinkWithStyle
                        className={classnames(
                            'block text-body-bold-14 tracking-regular py-14 cursor-pointer',
                            'transition-all duration-400',
                            'hover:bg-gray',
                            {
                                'bg-gray':
                                    (isIncluded && isNotExcludedPath && link !== '/') ||
                                    (link === '/' && router.asPath === link)
                            }
                        )}
                        href={link}
                        onClick={() => {
                            if (isMobile) {
                                collapseSidebar();
                            }
                            if (onClick) onClick();
                        }}>
                        <div className={classnames('flex pl-20 pr-16 space-x-16 ', {})}>
                            {icon}
                            {!isSidebarCollapsed && <div className="my-auto">{text}</div>}
                        </div>
                    </LinkWithStyle>
                </div>
            </PermissionsGate>
        </div>
    );
}

export default SidebarNavigationItem;
