import classnames from 'classnames';
import Image from 'next/image';
import React, { useEffect, useState } from 'react';

import { CinemaDto } from '@cineamo/legacy-frontend-lib/src/models/cinema/CinemaDto.types';

import { getCinemaLogo } from '@/src/helper/cinema-helper';
import { loadAsset } from '@cineamo/legacy-frontend-lib/src/helper/asset-helper';

import { IconLocation24 } from '@cineamo/legacy-frontend-lib/src/icons/menu/ic-location';

const defaultCinemaImage = loadAsset('/images/placeholders/im-cinemaDummy.png');

export type CinemaPickerListItemProps = {
    className?: string;
    cinema: CinemaDto;
    onClick?: (cinema: CinemaDto) => void;
};

function CinemaPickerListItem(props: CinemaPickerListItemProps) {
    const { className, cinema } = props;

    // const router = useRouter();

    const [error, setError] = useState(false);

    useEffect(() => {
        setError(false);
    }, [cinema]);

    // const { showLoadingAnimation, hideLoadingAnimation } = useLoadingAnimation();

    function changeSelectedCinema(newCinema: CinemaDto) {
        if (props.onClick) {
            props.onClick(newCinema);
        }

        // changeCinemaRedirect(router, newCinema, showLoadingAnimation, hideLoadingAnimation);
    }

    const imageUrl = getCinemaLogo(cinema, true);

    return (
        <div
            className={classnames(
                className,
                'w-full px-16 py-8 flex flex-row cursor-pointer items-center transition-all duration-200',
                'hover:bg-purple hover:bg-opacity-20 active:opacity-80',
                'select-none group'
            )}
            onClick={() => changeSelectedCinema(cinema)}
            title={cinema.name}>
            <div className="flex flex-row items-center space-x-8 mr-8">
                <div className={classnames('rounded-full relative flex-none overflow-hidden w-24 h-24')}>
                    <Image
                        src={!error && imageUrl ? imageUrl : defaultCinemaImage}
                        layout="fill"
                        onError={() => setError(true)}
                        onErrorCapture={() => setError(true)}
                        objectFit="cover"
                        alt={`${cinema.name} cinema image`}
                    />
                </div>
                <span className="whitespace-nowrap overflow-ellipsis overflow-hidden">{cinema.name}</span>
            </div>
            <IconLocation24 className="flex-none ml-auto opacity-0 group-hover:opacity-100 transition-all duration-200" />
        </div>
    );
}

export default CinemaPickerListItem;
