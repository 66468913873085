import {CustomSVGProps} from '../types/CustomSVGProps.types';

export function IconTrend48(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="5" y="39" width="5" height="6" stroke={props.stroke || 'currentColor'} strokeWidth="2"/>
            <rect x="4.5" y="44.5" width="39" height="1" stroke={props.stroke || 'currentColor'}/>
            <rect x="16" y="31" width="5" height="14" stroke={props.stroke || 'currentColor'} strokeWidth="2"/>
            <rect x="27" y="23" width="5" height="22" stroke={props.stroke || 'currentColor'} strokeWidth="2"/>
            <rect x="38" y="15" width="5" height="30" stroke={props.stroke || 'currentColor'} strokeWidth="2"/>
            <path d="M4 33L44 4" stroke={props.stroke || 'currentColor'} strokeWidth="2"/>
            <path d="M43 10L44 4L38 3" stroke={props.stroke || 'currentColor'} strokeWidth="2"/>
        </svg>
    );
}

export function IconTrend24(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="2" y="19" width="4" height="4" stroke={props.stroke || 'currentColor'} strokeWidth="2"/>
            <rect x="10" y="15" width="4" height="8" stroke={props.stroke || 'currentColor'} strokeWidth="2"/>
            <rect x="18" y="10" width="4" height="13" stroke={props.stroke || 'currentColor'} strokeWidth="2"/>
            <path d="M3 16.5L21 2" stroke={props.stroke || 'currentColor'} strokeWidth="2"/>
            <path d="M21 7V2L16 1" stroke={props.stroke || 'currentColor'} strokeWidth="2"/>
            <rect x="1.5" y="22.5" width="21" height="1" stroke={props.stroke || 'currentColor'}/>
        </svg>
    );
}