import { useTranslation } from 'next-i18next';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import TwoButtonComponent from '@cineamo/legacy-frontend-lib/src/components/ui/buttons/TwoButtonComponent';
import Modal, { ModalProps } from '@cineamo/legacy-frontend-lib/src/components/ui/modal/Modal';

import { updateCinema } from '@cineamo/legacy-frontend-lib/src/api/cinema/cinema-api';

import { apiResponseHandler } from '@cineamo/legacy-frontend-lib/src/helper/api-response-helper/ApiResponseHelper';
import { dateTodayMinus } from '@cineamo/legacy-frontend-lib/src/helper/date-helper';
import { showToastNew } from '@cineamo/legacy-frontend-lib/src/helper/toast-helper';

import useLastSelectedCinema from '@/src/hooks/useLastSelectedCinema';

import { useUserStore } from '@cineamo/legacy-frontend-lib/src/store/user/userStore';

export type CineamoTrailerConfirmModalProps = ModalProps;

export default function CineamoTrailerConfirmModal(props: CineamoTrailerConfirmModalProps): JSX.Element {
    const { t } = useTranslation(['common']);

    const { user } = useUserStore();
    const { lastSelectedCinema: cinema, setLastSelectedCinema } = useLastSelectedCinema(user);

    const [isProcessing, setIsProcessing] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(null);

    function onCineamoTrailerConfirmClick() {
        setIsProcessing(true);
        updateCinema({
            id: cinema.id,
            isCineamoTrailerConfirmed: true
        }).then((response) => {
            apiResponseHandler(response, (successResponse) => {
                setLastSelectedCinema(successResponse);
                showToastNew({
                    toastType: toast.info,
                    messageTitle: t('common:trailer'),
                    messageBody: t('common:screening-confirmed')
                });
            });

            setModalIsOpen(false);
            setIsProcessing(false);
        });
    }

    useEffect(() => {
        if (cinema && modalIsOpen !== false) {
            if (
                !cinema.cineamoTrailerConfirmedDatetime ||
                new Date(cinema.cineamoTrailerConfirmedDatetime) <= dateTodayMinus(7 * 4)
            ) {
                setModalIsOpen(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cinema]);

    return (
        <Modal
            {...props}
            title={t('common:screening-cineamo-trailer-question')}
            onClose={null}
            setModalIsOpen={setModalIsOpen}
            modalIsOpen={!!modalIsOpen}
            t={t}>
            <div className="flex flex-col items-center overflow-y-auto space-y-24">
                <span className="w-full">{t('common:screening-cineamo-trailer-question-description')}</span>

                <TwoButtonComponent
                    primaryLabel={t('common:yes')}
                    primaryAction={onCineamoTrailerConfirmClick}
                    secondaryLabel={t('common:no')}
                    secondaryAction={() => setModalIsOpen(false)}
                    isProcessing={isProcessing}
                    stretched
                />
            </div>
        </Modal>
    );
}
