import { useTranslation } from 'next-i18next';
import React from 'react';

import SideNavigationItem from '@/components/navigation/SidebarNavigationItem';
import { NavigationDataGroup, NavigationDataItem } from '@/components/navigation/data/NavigationDataItem.type';

type NavigationItemGroupProps = {
    navigation: NavigationDataGroup;
    isSidebarCollapsed: boolean;
    setIsSidebarCollapsed: (isSideBarCollapsed: boolean) => void;
};

export default function NavigationItemGroup(props: NavigationItemGroupProps) {
    const { navigation, isSidebarCollapsed } = props;
    const { t } = useTranslation('navigation');

    function isNavigationDataItemTypeGuard(item): item is NavigationDataItem {
        return !React.isValidElement(item);
    }

    return (
        <div>
            {navigation.label && !isSidebarCollapsed && (
                <label className="block pl-20 pr-16 mt-28 text-gray-dark uppercase tracking-wider font-montserrat-semibold text-overline-10">
                    {t(navigation.label)}
                </label>
            )}

            {navigation.children?.map((item: NavigationDataItem | JSX.Element, index: number) =>
                isNavigationDataItemTypeGuard(item) ? (
                    <SideNavigationItem
                        key={index}
                        link={item.href}
                        icon={item.icon}
                        text={t(item.title)}
                        neededPermission={item.necessaryPermission}
                        excludedPath={item.excludedPath}
                        isSidebarCollapsed={isSidebarCollapsed}
                        setIsSidebarCollapsed={props.setIsSidebarCollapsed}
                    />
                ) : (
                    <div
                        key={index}
                        className="pl-20 pr-16">
                        {item}
                    </div>
                )
            )}
        </div>
    );
}
