import classnames from 'classnames';
import { useTranslation } from 'next-i18next';
import React, { Dispatch, SetStateAction } from 'react';

import CinemaPickerListItem from '@/components/pages/cinema/CinemaPickerListItem';
import CinemaProfileImageRound from '@/components/ui/image/CinemaProfileImageRound';
import ListLoadingSpinner from '@cineamo/legacy-frontend-lib/src/components/ui/animation/ListLoadingSpinner';
import Button, { ButtonStyle } from '@cineamo/legacy-frontend-lib/src/components/ui/buttons/Button';
import { BasicInputStyle } from '@cineamo/legacy-frontend-lib/src/components/ui/input/BasicInput';
import SearchBar from '@cineamo/legacy-frontend-lib/src/components/ui/input/SearchBar';
import TmpPaginator from '@cineamo/legacy-frontend-lib/src/components/ui/table/TmpPaginator';

import { CinemaDto } from '@cineamo/legacy-frontend-lib/src/models/cinema/CinemaDto.types';

import { Role } from '@cineamo/legacy-frontend-lib/src/constants/role-constants';

import { CinemaListResponse } from '@cineamo/legacy-frontend-lib/src/api/ApiListResponse.types';
import { ApiResponse } from '@cineamo/legacy-frontend-lib/src/api/ApiResponse.types';

import { constructCinemaAddress } from '@/src/helper/cinema-helper';

import { useUserStore } from '@cineamo/legacy-frontend-lib/src/store/user/userStore';

import { IconLocation24 } from '@cineamo/legacy-frontend-lib/src/icons/menu/ic-location';

export type CinemaPickerDropdownProps = {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    currentCinema: CinemaDto;
    onSearchCinema: (query: string) => void;
    query: string;
    page: number;
    setPage: Dispatch<SetStateAction<number>>;
    cinemasEmployedAtResponse: ApiResponse<CinemaListResponse>;
    isLoadingCinemasEmployedAt: boolean;
    isFetchingCinemasEmployedAt: boolean;
    selectCinemaHandler: (cinema: CinemaDto) => void;
    onSignOutFromCinemaClick: () => void;
};

function CinemaPickerDropdown(props: CinemaPickerDropdownProps): JSX.Element {
    const {
        open,
        currentCinema,
        onSearchCinema,
        query,
        page,
        setPage,
        cinemasEmployedAtResponse,
        isLoadingCinemasEmployedAt,
        isFetchingCinemasEmployedAt,
        selectCinemaHandler,
        onSignOutFromCinemaClick
    } = props;

    const { t } = useTranslation('navigation');

    const { userType } = useUserStore();

    function constructAddress(currentCinema: CinemaDto): JSX.Element {
        return (
            <div className="flex flex-col justify-center font-montserrat-bold whitespace-nowrap overflow-ellipsis overflow-hidden mx-10">
                {currentCinema ? (
                    <>
                        <div className="whitespace-nowrap overflow-ellipsis overflow-hidden">{currentCinema?.name}</div>
                        <div className="whitespace-nowrap overflow-ellipsis overflow-hidden text-body-bold-16">
                            {constructCinemaAddress(currentCinema)}
                        </div>
                    </>
                ) : (
                    <span>{t('cinema-picker.choose-cinema')}</span>
                )}
            </div>
        );
    }

    function onSignOutFromCinemaClickInternal() {
        if (onSignOutFromCinemaClick) {
            onSignOutFromCinemaClick();
        }
    }

    return (
        <div
            className={classnames(
                open ? 'max-h-screen' : 'max-h-0',
                'fixed left-0 w-full px-12',
                'md:absolute md:left-auto md:right-0 md:w-auto md:min-w-300 md:px-0',
                'transform transition-all duration-200 overflow-hidden'
            )}>
            <div className="flex flex-col w-full rounded-4 top-34 bg-white text-darkBlue shadow-2xl divide-gray divide-opacity-50 py-12 space-y-3">
                <div className="w-full flex flex-row px-16 items-center">
                    <CinemaProfileImageRound
                        cinema={currentCinema}
                        size="32"
                    />
                    {constructAddress(currentCinema)}
                    {currentCinema && <IconLocation24 className="flex-none ml-auto" />}
                </div>

                {userType.includes(Role.ROLE_ADMIN) && currentCinema && (
                    <Button
                        style={ButtonStyle.text_only}
                        onClick={onSignOutFromCinemaClickInternal}>
                        {t('cinema-picker.log-out-from-cinema')}
                    </Button>
                )}

                <div className="h-1 w-full bg-gray" />

                <SearchBar
                    className="mx-8"
                    basicInputProps={{
                        style: BasicInputStyle.gray_on_white_bg
                    }}
                    value={query}
                    placeholder={t('cinema-picker.search-for-cinema')}
                    onChange={onSearchCinema}
                />

                <div className="flex flex-col h-full overflow-y-auto">
                    {!(isLoadingCinemasEmployedAt || isFetchingCinemasEmployedAt) &&
                        cinemasEmployedAtResponse?._embedded?.cinemas?.map((cinema) => (
                            <CinemaPickerListItem
                                key={cinema?.id}
                                className="w-full"
                                cinema={cinema}
                                onClick={selectCinemaHandler}
                            />
                        ))}

                    {!(isLoadingCinemasEmployedAt || isFetchingCinemasEmployedAt) &&
                        (!cinemasEmployedAtResponse || cinemasEmployedAtResponse._total_items <= 0) && (
                            <span className="px-16">{t('cinema-picker.no-cinema-in-list')}</span>
                        )}

                    {(isLoadingCinemasEmployedAt || isFetchingCinemasEmployedAt) && (
                        <ListLoadingSpinner
                            className="p-24"
                            dotClass="bg-darkBlue"
                        />
                    )}
                </div>

                {cinemasEmployedAtResponse?._page_count > 1 && (
                    <TmpPaginator
                        page={page}
                        setPage={setPage}
                        totalItems={cinemasEmployedAtResponse?._total_items}
                        pageCount={cinemasEmployedAtResponse?._page_count}
                    />
                )}
            </div>
        </div>
    );
}

export default CinemaPickerDropdown;
