import {CustomSVGProps} from '../../types/CustomSVGProps.types';


export function IconNavHandleSateOpen(props: CustomSVGProps) : JSX.Element {
    return (
        <svg {...props}
            width="13" height="48" viewBox="0 0 13 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 0V48C17.1259 36.5827 17.1259 11.4173 0 0Z" fill="#F2F2F2"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M0 24L6 18L7.41421 19.4142L2.82843 24L7.41421 28.5858L6 30L0 24Z"
                  fill={props.fill || 'currentColor'}/>
        </svg>


    )
}


export function IconNavHandleSateClose(props: CustomSVGProps) : JSX.Element {
    return (
        <svg {...props}
            width="13" height="48" viewBox="0 0 13 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 0V48C17.1259 36.5827 17.1259 11.4173 0 0Z" fill="#F2F2F2"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M8 24L2 18L0.585786 19.4142L5.17157 24L0.585787 28.5858L2 30L8 24Z"
                  fill={props.fill || 'currentColor'}/>
        </svg>


    )
}

